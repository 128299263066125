









import { Vue, Component, Prop } from 'vue-property-decorator'
import BroadcastItem from './BroadcastItem.vue'

@Component({
  name: 'broadcast',
  components: {
    BroadcastItem
  }
})
export default class Broadcast extends Vue {
  @Prop({}) private list: Broadcast
}
