







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'indexMenu'
})
export default class IndexMenu extends Vue {
  @Prop({}) private collapse: boolean
  @Prop({}) private lockings: boolean

  private menus: any = ''
  private isLeave = false
  private currentAccount: string | null = ''
  private currentStatus = {}

  get activeIndex() {
    /**
     * 侧边菜单项只展示至二级菜单，若页面路径为二级路由下的子路由，则其父节点为激活状态
     */
    const { matched } = this.$route

    return matched[Math.min(matched.length - 1, 2)].path
  }

  @Watch('collapse')
  watchCollapse(e: boolean) {
    if (e === false && this.lockings === true) {
      this.isLeave = true
    } else {
      this.isLeave = false
    }
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) || '')
    }
    this.currentStatus = this.currentStatus ? this.currentStatus : {}
    Object.assign(this.currentStatus, { isLeave: this.isLeave })
    if (this.currentAccount) {
      localStorage.setItem(this.currentAccount, JSON.stringify(this.currentStatus))
    }
  }

  get menu() {
    return this.$store.getters.menu || this.menus
  }

  mounted() {
    this.menus = this.$store.state.dashboard.menu
  }

  onMenuSelect(path: string) {
    const { href, route } = this.$router.resolve(path)
    const { blank } = route.meta ?? {}

    if (blank) {
      open(href)
    } else {
      this.$router.push(path)
    }
  }
}
