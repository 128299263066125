







































import { Vue, Component, Ref } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElTree as ElTreeInstance } from 'element-ui/types/tree'
import api from '@/api'
import { is } from '@/utils/helpers'
import { treeFind } from 'operation-tree-node'
import filterStorage from '@/components/common/FilterHub/storage'

@Component({
  name: 'areaDialog',
  components: { InputSearch }
})
export default class AreaDialog extends Vue {
  @Ref('treeNode') private treeNode: ElTreeInstance<string, AnyObj>
  private visible = false
  private filterText: string | null = ''
  private areaList: any[] = []
  private rootId = ''
  private rootConfig = {}
  get user() {
    return this.$store.state.user || {}
  }

  get app() {
    const routes: any[] = this.$store.state.routes
    const star = treeFind(routes, r => r.id === 'home')
    if (star) {
      return 'star'
    } else {
      const country = treeFind(routes, r => r.id === 'home-country')
      if (country) {
        return 'country'
      }
    }
    return 'star'
  }

  mounted() {
    this.rootId = this.getRootConfig().id || this.user.scope
  }

  public show() {
    this.visible = true

    this.getAreasList()
  }

  private getRootConfig() {
    const rootConfig = localStorage.getItem('rootConfig')

    if (rootConfig) {
      return JSON.parse(rootConfig)
    }
    return {}
  }

  private async getAreasList() {
    if (this.app == 'country') {
      const { data } = await api.europa.getRootAreaTree()

      if (data.code == 0) {
        this.areaList = this.formatAreas([data.data])
      }
    } else {
      const { data } = await api.base.getRootAreaTree()

      if (data.code == 0) {
        this.areaList = [data.data]
      }
    }
  }

  private formatAreas(areas: any[]) {
    for (const area of areas) {
      if (is.num(area.level) && area.level <= 1) {
        area.children = []
      } else if (area.children) {
        this.formatAreas(area.children)
      }
    }
    return areas
  }

  private change(item: any, isChecked: boolean) {
    if (isChecked) {
      this.rootId = item.id
      this.rootConfig = item
      this.treeNode.setCheckedKeys([item.id])
    }
  }
  private searchArea(val: any) {
    this.filterText = val
    if (this.filterText === null || this.filterText.match(/^[ ]*$/)) {
      return false
    } else {
      const treeNode: any = this.$refs.treeNode
      treeNode.search(this.filterText)
    }
  }

  private async submit() {
    const rootId = this.getRootConfig().id
    const { scope } = this.$store.state.user

    if (rootId && rootId !== scope) {
      filterStorage.clear()
    }

    if (this.rootId !== rootId) {
      if (scope === this.rootId) {
        localStorage.removeItem('rootConfig')
      } else {
        localStorage.setItem('rootConfig', JSON.stringify(this.rootConfig))
      }

      location.reload()
    }
    this.visible = false
  }

  private cancel() {
    this.visible = false
  }
}
