





import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'broadcastItem',
  components: {}
})
export default class BroadcastItem extends Vue {
  @Prop({}) private item: Broadcast
  private timer = 0

  mounted() {
    this.showAllText()
  }

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  private showAllText() {
    const msg = this.$refs.msg as HTMLDivElement
    const msgW = msg.clientWidth
    const elW = this.$el.clientWidth
    let distance = 0
    if (msgW > elW) {
      distance = msgW - elW
      this.move(distance)
    }
  }

  private move(d: any) {
    this.timer = window.setTimeout(() => {
      const msg = this.$refs.msg as HTMLElement
      msg.animate(
        [
          { transform: 'translateX(0px)' },
          { transform: `translateX(-${d}px)` },
          { transform: 'translateX(0px)' }
        ],
        {
          duration: 4000,
          iterations: Infinity
        }
      )
    }, 2000)
  }
}
