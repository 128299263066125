

























































import { Vue, Component, Ref } from 'vue-property-decorator'
import TimeNow from '@/components/common/TimeNow/index.vue'
import LeftMenu from '../menu/index.vue'
import { actionTypes } from '@/store'
import AreaSelect from './AreaDialog.vue'
import ThemeDialig from './ThemeDialog.vue'

@Component({
  name: 'indexHeader',
  components: {
    LeftMenu,
    TimeNow,
    AreaSelect,
    ThemeDialig
  }
})
export default class IndexHeader extends Vue {
  @Ref('areaNode') private areaNode: AreaSelect
  @Ref('themeDialog') private readonly themeDialog: ThemeDialig

  private collapse = false
  private userBox = true
  private isLeftMenu = false
  private currentStatus: any
  private currentAccount: null | string = ''
  private isChrome = true
  private isSafari = true
  private showTip = true
  private firstLogin = true

  get user() {
    return this.$store.state.user || {}
  }

  get name() {
    return this.user.name || ''
  }
  get username() {
    return this.user.username || ''
  }
  get hide() {
    return this.user.hide || ''
  }
  get project() {
    return this.$store.state.dashboard.title || ''
  }

  get logo() {
    const { logoImg } = this.$store.state.features
    if (logoImg) {
      return logoImg
    }
    return ''
  }

  beforeMount() {
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    this.currentStatus = this.currentStatus ? this.currentStatus : {}
    this.firstLogin = localStorage.getItem('firstLogin') === 'false' ? false : true
    const menuCollapse = this.currentStatus.menuCollapse
    menuCollapse && (this.collapse = menuCollapse)
    const browseCheck = window.navigator.userAgent
    this.isChrome =
      browseCheck.indexOf('Chrome') > 0 &&
      browseCheck.indexOf('Safari') > 0 &&
      browseCheck.indexOf('Edge') < 0
    this.isSafari =
      browseCheck.indexOf('Chrome') < 0 &&
      browseCheck.indexOf('Safari') > 0 &&
      browseCheck.indexOf('Edge') < 0
    this.showTip = this.isChrome || this.isSafari
  }
  mounted() {
    this.$nextTick(() => {
      const personImg = document.getElementsByClassName('person-img')[0] as HTMLElement
      personImg.style.margin = '5px'
      if (!this.collapse) {
        document.body.classList.remove('sidebar-hidden')
      } else {
        document.body.classList.add('sidebar-hidden')
      }
    })
  }
  changeArea() {
    this.areaNode.show()
  }

  changeTheme() {
    this.themeDialog.show()
  }

  private turnHome() {
    this.$router.push('/')
  }

  private hideTip() {
    this.showTip = true
    localStorage.setItem('firstLogin', 'false')
  }

  private sidebarToggle(e: Event) {
    e.preventDefault()
    if (this.collapse) {
      document.body.classList.remove('sidebar-hidden')
      this.collapse = false
      this.isLeftMenu = true
    } else {
      document.body.classList.add('sidebar-hidden')
      this.collapse = true
      this.isLeftMenu = false
    }
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    this.currentStatus = this.currentStatus ? this.currentStatus : {}
    Object.assign(
      this.currentStatus,
      { menuCollapse: this.collapse },
      { lockings: this.isLeftMenu }
    )
    if (this.currentAccount) {
      localStorage.setItem(this.currentAccount, JSON.stringify(this.currentStatus))
    }
  }
  private async logout() {
    localStorage.setItem('firstLogin', '')
    localStorage.removeItem('rootConfig')
    this.$store.dispatch(actionTypes.CLEAR)
    localStorage.removeItem('currentAreaId')
    this.clearPreArea()
    const redirectUri = location.origin || '/'

    this.$store.state.keycloak.logout({ redirectUri })
  }

  private clearPreArea() {
    const currentAccount = localStorage.getItem('currentAccount')
    if (currentAccount) {
      localStorage.removeItem(currentAccount + '-area')
      localStorage.removeItem(currentAccount + '-clearTime')
    }
  }

  private accountManagement() {
    this.$store.state.keycloak?.accountManagement()
  }
  private leave(e: boolean) {
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    this.currentStatus = this.currentStatus ? this.currentStatus : {}
    const isLeave = this.currentStatus.isLeave
    if (this.isLeftMenu === true) {
      this.collapse = false
    } else if (e === true && isLeave === true) {
      document.body.classList.remove('sidebar-hidden')
      this.collapse = false
    } else if (this.isLeftMenu === false) {
      this.collapse = true
      document.body.classList.add('sidebar-hidden')
    }
  }
  private enter() {
    document.body.classList.remove('sidebar-hidden')
    this.collapse = false
  }
}
