



const WEEK_MAP: Obj<number, string> = {
  0: '星期日',
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六'
}
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'timenow'
})
export default class Timenow extends Vue {
  private time = new Date()
  private timer: number
  mounted() {
    this.timer = window.setInterval(() => {
      this.time = new Date()
    }, 1000)
  }
  // 组装所有的时间
  private assembleTime(time: any) {
    return this._parseDate(time) + ' ' + this._parseWeekTime(time) + ' ' + this._parseTime(time)
  }
  private _parseDate(time: Date) {
    const t = time
    return t.getFullYear() + '-' + (t.getMonth() + 1) + '-' + t.getDate() + ''
  }
  private _parseTime(time: Date) {
    const t = time
    return t.toTimeString().split(' ')[0]
  }
  private _parseWeekTime(time: Date) {
    const t = time
    return WEEK_MAP[t.getDay()]
  }
  beforeDestroy() {
    // 组件销毁前clear掉定时器，避免可能的内存泄露
    clearInterval(this.timer)
  }
}
