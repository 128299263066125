















import { Vue, Component } from 'vue-property-decorator'
import theme, { themeName } from '@/libs/theme'

@Component
export default class ThemeDialog extends Vue {
  private visible = false
  private themes = [
    { label: '深色', value: 'dark' },
    { label: '浅色', value: 'light' }
  ]
  private value = themeName

  show() {
    this.visible = true
  }

  private change(value: 'dark' | 'light') {
    this.value = value
    theme.setTheme(value)

    this.$confirm('主题切换将在刷新页面后生效，是否立即刷新？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      callback: action => {
        if (action === 'confirm') {
          location.reload()
        }
      }
    })
  }
}
