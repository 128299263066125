
































import { Vue, Component, Watch } from 'vue-property-decorator'
import LayoutHeader from './header/header.vue'
import LayoutContent from './content/content.vue'

@Component({
  name: 'indexMain',
  components: {
    LayoutHeader,
    LayoutContent
  }
})
export default class IndexMain extends Vue {
  private isShowHead = true

  @Watch('$route')
  watchRoute() {
    this.setMain()
  }

  get project() {
    return this.$attrs.project
  }

  mounted() {
    this.setMain()
  }

  private setMain() {
    const mainContainer = document.getElementsByClassName('main-container')[0] as HTMLElement
    const { fullscreen } = this.$route.meta ?? {}

    if (fullscreen) {
      mainContainer.style.padding = '0px'
      this.isShowHead = false
    } else {
      mainContainer.style.paddingTop = '48px'
      this.isShowHead = true
    }
  }
}
