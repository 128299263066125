




































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import moment from 'moment'
import IndexLayout from '@/components/layout/index/main.vue'
import FengMap from '@/components/common/FengMap/index.vue'
import BroadcastList from '@/components/common/Broadcast/index.vue'
import store, { mutationTypes } from '@/store'
import { repoType, getAttr } from '@/utils'
import { variables } from '@/libs/theme'
import { is } from '@/utils/helpers'
import { secure } from '@/utils/secureRules'

const appName = process.env.VUE_APP_NAME

@Component({ components: { IndexLayout, FengMap, BroadcastList } })
export default class DashboardPage extends Vue {
  @Ref() readonly fengmapNode: Vue

  public title = '智能商业认知系统'
  public appName = appName
  public personList: any[] = []
  public personFilter: AnyObj = {}

  private shadow9Color = variables.shadow9Color
  private personMsg = ''
  private menu: any
  private areas: any[] = []
  private showMap = false
  private showDetailDialog = false
  private isShowTopNotice = true
  private trip: any = null
  private navImg = ''
  private showClose = false
  public hideHomeMap = false
  private domPosition = {
    button: { x: 0, y: 20, type: 'RIGHT_BOTTOM' },
    groupControl: { x: 20, y: 122, type: 'RIGHT_BOTTOM' }
  }
  private timer: any = null
  private uuid: any[] = []
  created() {
    const { title, path } = store.state.features

    this.title = title
    if (is.arr(path)) {
      // 临时方案，home为商业感知系统首页的id
      this.isShowTopNotice = path.includes('home') || !path.length
    }

    this.$store.commit(mutationTypes.SET_DASHBOARD, this)
  }
  get user() {
    return this.$store.state.user
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  get personListFiltered() {
    return this.personList.filter(person => {
      if (person.areas.length > 0) {
        if (this.filterArea(person)) {
          if (this.personFilter.identity) {
            const { tagTypes, tagIds, strange } = this.personFilter.identity
            const personTag = getAttr(person, 'tags[0]', false)
            if (personTag) {
              if (tagIds && tagIds.includes(personTag.id)) return true
              if (tagTypes && tagTypes.includes(personTag.type)) return true
            } else {
              return strange
            }
          } else {
            return true
          }
        }
      }
      return false
    })
  }
  get msgList() {
    const msg: any[] = []
    this.personList.forEach(person => {
      const areas = getAttr(person, 'areas', false)
      if (!this.isRootAccount) {
        // 切换视角时过滤掉不可见区域下的人
        const filterArea = areas.filter((i: AnyObj) => i.id === this.root)
        if (filterArea.length === 0) return
      }
      const type = getAttr(person, 'tags[0].type', false)
      if (!type || type === 'error') return
      if (!person.showMsg) return
      const tag = getAttr(person, 'tags[0].name', false) || repoType(type)
      const name = getAttr(person, 'name', false)
      const maskName = name ? secure.fullName(name) : '-'
      msg.push({
        msg:
          type === 'black'
            ? `${tag} ${maskName} 进入场内，请及时处理！`
            : type === 'vip'
            ? `${tag} ${maskName} 进入场内，请及时接待！`
            : `${tag} ${maskName} 进入场内！`,
        id: person.id
      })
    })
    return msg
  }
  get isShowClose() {
    return this.showClose || this.showMap
  }

  @Watch('personList')
  watchPersonList(list: any) {
    const len = window.boardNum ? window.boardNum : 200
    while (list && list.length > len) {
      list.pop()
    }
  }

  @Watch('canScroll')
  watchScroll() {
    this.sortPersonList()
  }

  @Watch('showDetailDialog')
  watchShowDetailDialog(bol: boolean) {
    this.hideHomeMap = !!bol
    this.$nextTick(() => {
      this.showMap = false
    })
    if (this.showDetailDialog) {
      setTimeout(() => {
        if (this.showDetailDialog) {
          this.showClose = true
        }
      }, 2000)
      this.isTimeout()
    } else {
      this.showClose = false
    }
  }

  @Watch('$route.path')
  watchPath() {
    this.showDetailDialog = false
  }

  beforeMount() {
    const loadingDom = document.getElementById('loading')
    if (loadingDom) {
      document.body.removeChild(loadingDom)
    }

    const currentAccount = this.user.username || ''
    localStorage.setItem('currentAccount', currentAccount)
    const currentStatus = JSON.parse(window.localStorage.getItem(currentAccount) as string)
    if (currentStatus) {
      const signBoard = currentStatus.boardStatus
      if (signBoard === 'off') {
        return
      }
    }

    if (this.isShowTopNotice) {
      this.startWsConnection()
    }
  }

  private loadComplete(val: AnyObj) {
    this.uuid.push(val.map.Ot.uuid)
    this.showMap = true
  }
  public filterArea(person: AnyObj) {
    let flag = false
    if (this.personFilter.areas && this.personFilter.areas.length > 0) {
      person.areas.forEach((obj: AnyObj) => {
        if (this.personFilter.areas.includes(obj.id)) {
          flag = true
        }
      })
    } else {
      if (!this.isRootAccount) {
        person.areas.forEach((obj: AnyObj) => {
          if (this.root === obj.id) {
            flag = true
          }
        })
      } else {
        flag = true
      }
    }
    return flag
  }
  public startWsConnection() {
    if (this.$ws) this.$ws.close()
    const token = store.state.keycloak?.kcOptions?.token || ''
    this.$ws.connect({ authorization: encodeURIComponent(token) })
    this.$ws.on('connect', () => {
      if (this.socketMessage) {
        this.socketMessage.close()
        this.socketMessage = null
      }
      // eslint-disable-next-line no-console
      console.log('websocket successfully connected !')
    })
    this.$ws.on('error', () => {
      if (this.$route.path === '/home') {
        if (!this.socketMessage) {
          this.socketMessage = this.$message({
            type: 'error',
            duration: 0,
            message: 'ws连接错误',
            showClose: true
          })
        }
      }
    })
    this.$ws.on('reconnect', () => {
      // eslint-disable-next-line no-console
      console.log('reconnect......')
      const token = store.state.keycloak?.kcOptions?.token || ''
      this.$ws.connect({ authorization: token })
    })
    this.$ws.on('close', () => {
      // eslint-disable-next-line no-console
      console.log('websocket close')
    })
    this.$ws.on('message', (m: any) => {
      if (m) {
        if (getAttr(m, 'areas[0].status') === 'leave') {
          this.del(m)
          return
        }
        if (getAttr(m, 'capture.quality.qualified', false)) {
          this.add(m)
        }
        this.personMsg = m
      }
    })
  }
  private add(m: AnyObj) {
    const person = this.personList.find(l => l.id === m.id)
    if (person) {
      const type = getAttr(person, 'tags[0].type', false)
      if (!m.tags) m.tags = []
      if (person.edited === true && type === 'black') {
        m.edited = true
      }
      Object.assign(person, m)
    } else {
      if (!m.tags) m.tags = []
      if (getAttr(m, 'tags[0].type', false)) {
        m.showMsg = function () {
          return setTimeout(() => {
            this.showMsg = null
          }, 30000)
        }
        m.showMsg()
      }
      m.edited = false
      this.personList.unshift(m)
    }
    this.sortPersonList()
  }
  private del(m: AnyObj) {
    const personIndex = this.personList.findIndex(l => l.id === m.id)
    if (personIndex !== -1) {
      const person = this.personList[personIndex]
      if (person.showMsg) {
        clearTimeout(person.showMsg)
      }
      this.personList.splice(personIndex, 1)
    }
  }
  private sortPersonList() {
    if (this.canScroll) {
      const list = [...this.personList]
      list.sort((a, b) => moment(b.capture.time).valueOf() - moment(a.capture.time).valueOf())
      const personListId = this.personList.map(l => l.id)
      const listId = list.map(l => l.id)
      if (personListId.join('') === listId.join('')) return
      this.personList = list
    }
  }
  private closeDialog() {
    if (this.showDetailDialog) {
      this.fengmapNode.clickClose()
      if (this.uuid.length > 0) {
        const map = window.maps.find((i: any) => i.Ot.uuid === this.uuid[0])
        this.uuid.shift()
        if (map && map.visible) {
          const arr = window.maps.filter((i: any) => i.Ot.uuid !== map.Ot.uuid)
          window.maps = [...arr]
          map.dispose()
        }
      }
    }
  }
  private isTimeout() {
    this.timer = setTimeout(() => {
      if (!this.showMap) {
        this.showMap = true
      }
    }, 10000)
  }

  destroyed() {
    if (this.timer) clearTimeout(this.timer)
  }
}
